import React, { useState } from 'react';
import {
  Box,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import MenuIcon from '@mui/icons-material/Menu';
import { colors } from '../../constants/colors';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

let refLinks: any = {
  Offerings: 'offerings',
  'Why AssetTribe': 'whyAssetTribe',
  'About AssetTribe': 'aboutAssetTribe',
  Contact: 'contactAssetTribe',
};

export default function SwipeableTemporaryDrawer() {
  const t = useTranslations('Navigation');
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        bgcolor: colors.lightBlack2,
        height: '100vh',
        pt: 5,
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          t('offerings'),
          t('whyAssetTribeLink'),
          t('aboutLink'),
          t('contact'),
        ].map((text, index) => {
          return (
            <Link passHref={true} href={`#${refLinks[text]}`} key={text}>
              <ListItem button>
                <Typography mb={2} style={{ fontSize: 14, fontWeight: 700 }}>
                  {text}
                </Typography>
              </ListItem>
            </Link>
          );
        })}
        {/* <Typography style={{ color: colors.pink }}>Login</Typography> */}
      </List>
    </Box>
  );

  return (
    <div>
      {(['left'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <MenuIcon
            sx={{ cursor: 'pointer', color: colors.pink, mt: 1 }}
            onClick={toggleDrawer(anchor, true)}
          />
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export const LandingHeader = ({ openLoginModal, setOpenLoginModal }: any) => {
  const smallScreen = useMediaQuery('(max-width:800px)');
  const router = useRouter();
  const t = useTranslations('Navigation');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: smallScreen ? '10px 20px' : '10px 50px',
      }}
    >
      <div style={{ display: 'flex', columnGap: 30 }}>
        {['RETURN TO HOME PAGE'].map((item) => {
          return (
            <Link key={item} href="https://assettribe.io">
              <Chip
                icon={<ArrowBackIcon />}
                label={
                  <Typography style={{ cursor: 'pointer' }} fontSize="small">
                    {item}
                  </Typography>
                }
                variant="outlined"
                sx={{ backgroundColor: colors.lightBlack1 }}
              />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
