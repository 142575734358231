import { SxProps, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { colors } from '../../constants/colors';

interface Props {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactElement<any> | string | ReactNode;
  fontSize?: number;
  color?: string;
  opacity?: number;
}

export const DescriptionText = ({
  children,
  style,
  className,
  fontSize = 12,
  color = colors.white1,
  opacity = 1,
}: Props) => {
  return (
    <Typography style={{ fontSize: fontSize, opacity, color: color, ...style }}>
      {children}
    </Typography>
  );
};
