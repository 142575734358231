import { Divider, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { colors } from 'src/constants/colors';
import offerings from 'src/pages/api/admin/offerings';
import { DescriptionText } from '../common/DescriptionText';

export const LandingFooter = () => {
  const smallScreen = useMediaQuery('(max-width:800px)');
  const t = useTranslations('Footer');

  let footerNavLink = [
    {
      name: t('privacyPolicy'),
      url: '/wp-pages/privacy-policy',
      sourceUrl: 'https://assettribe.io/privacy-policy',
    },
    {
      name: t('termsOfUse'),
      url: '/wp-pages/terms-of-use',
      sourceUrl: '/wp-pages/legal',
    },
    {
      name: t('invsetorTerms'),
      url: '/wp-pages/terms-of-use',
      sourceUrl: 'https://assettribe.io/terms-of-use/',
    },
    {
      name: t('faq'),
      url: '/wp-pages/faqs',
      sourceUrl: 'https://assettribe.io/faqs/',
    },
    {
      name: t('sdfr'),
      url: '/sfdr',
      sourceUrl: '/sfdr',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: colors.darkBlueGrey,
        py: 5,
        px: smallScreen ? 8 : 15,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          fontSize: 16,
          color: colors.white1,
        }}
      >
        <Typography mb={2}>
          © {new Date().getFullYear()} {t('title')}
        </Typography>
        <div style={{ display: 'flex', columnGap: 20 }}>
          {footerNavLink.map((item) => {
            return (
              <a
                key={item.name}
                style={{ color: '#fff' }}
                href={item.sourceUrl}
                target="_blank"
                rel="noreferrer"
              >
                {item.name}
              </a>
              // <Link
              //   key={item.name}
              //   passHref={true}
              //   href={item.url}
              //   target="_blank"
              // >
              //   <Typography style={{ cursor: 'pointer' }}>
              //     {item.name}
              //   </Typography>
              // </Link>
            );
          })}
        </div>
      </div>
      <Divider color={colors.white1} sx={{ my: 4, height: 0.1 }} />
      <div
        style={{ opacity: 0.75, textAlign: smallScreen ? 'center' : 'left' }}
      >
        {/* <DescriptionText>{t('para1')}</DescriptionText> */}
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t.rich('disclaimer.para1', {
            b: (content) => <b>{content}</b>,
          })}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('disclaimer.para2')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('disclaimer.para3')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('disclaimer.para4')}
        </DescriptionText>
        <Typography fontSize={12} fontWeight={700}>
          {t('offerings.heading')}
        </Typography>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('offerings.para1')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('offerings.para2')}
        </DescriptionText>
        <Typography fontSize={12} my={2} fontWeight={700}>
          {t('localRestrictions.heading')}
        </Typography>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('localRestrictions.para1')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('localRestrictions.para2')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('localRestrictions.para3')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px' }}>
          {t('localRestrictions.para4')}
        </DescriptionText>
        <DescriptionText style={{ margin: '15px 0px', fontStyle: 'italic' }}>
          {t.rich('legalQuote.para1', {
            b: (content) => <b>{content}</b>,
          })}
        </DescriptionText>
        <ul>
          <li>
            <DescriptionText style={{ margin: '5px 0px', fontStyle: 'italic' }}>
              {t('legalQuote.bullet1')}
            </DescriptionText>
          </li>
          <li>
            <DescriptionText style={{ margin: '5px 0px', fontStyle: 'italic' }}>
              {t('legalQuote.bullet2')}
            </DescriptionText>
          </li>
          <li>
            <DescriptionText style={{ margin: '5px 0px', fontStyle: 'italic' }}>
              {t('legalQuote.bullet3')}
            </DescriptionText>
          </li>
          <li>
            <DescriptionText style={{ margin: '5px 0px', fontStyle: 'italic' }}>
              {t('legalQuote.bullet4')}
            </DescriptionText>
          </li>
        </ul>
      </div>
    </Box>
  );
};
